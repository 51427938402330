<template>
  <div class="main">
    <div class="main_box">
      <a-row>
        <a-col :span="7">
          <div class="right-side-assembly">
            <find-demand class="find-item" title="找项目" :technicalProjectContent="technicalProjectContent" />
            <find-demand class="find-demand" title="找需求" :technicalProjectContent="technicalProjectContent"/>
          </div>
        </a-col>
        <a-col :span="17" class="news-information-left">
          <journalism :newsData="content" @details="details"/>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center">
        <a-pagination v-show="content.length" v-model="pageNo" :total="total"  :default-page-size="pageSize" @change="onChange" style="text-align: center;padding-left: 270px;margin-top: 20px;margin-bottom: 20px"/>
      </a-row>
    </div>
  </div>
</template>
<script>
import FindDemand from '@comp/findDemand'
import Journalism from '@comp/Journalism'
import { industryInformation, getDict } from '@/api/index'
import { industryDetail } from '@/api/project'
import { Page } from '@/mixins/page'
import { Station } from '@/mixins/station'

export default {
  name: 'NewsInformation',
  mixins: [Page, Station],
  components: {
    FindDemand,
    Journalism
  },
  data() {
    return {
      content: [],
      technicalProjectContent: [],
      demandContent: [],
      projectRequirements: {
        // domain,
        type: 1 // 1项目；2者需求
      }
    }
  },
  methods: {
    /**
     * @description:获取列表
     */
    getList() {
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        stationId: this.stationId
      }
      // 获取新闻资讯
      industryInformation(params).then(res => {
        this.content = res.result.records
        this.total = res.result.total * 1
      })
    },
    /**
     * @description:获取点击的哪条数据
     * @param {*} id
     */
    details(id) {
      industryDetail({ id: id }).then(res => {
        if (this.stationId !== '' && this.stationId !== undefined) {
          this.$router.push({ name: 'newsDetail', query: { stationId: this.stationId, id: id }, params: res.result })
        } else {
          this.$router.push({ name: 'newsDetail', query: { id: id }, params: res.result })
        }
      })
    }
  },
  activated() {
    // 判断是否为子站
    this.getStationId()
    // 获取项目、需求
    getDict('application_field', { stationId: this.stationId }).then(res => {
      // this.technicalProjectContent = res.result
      // this.demandContent = res.result
      this.technicalProjectContent = res.result
    })
    this.getList()
  }
}
</script>
<style scoped>
@import "../assets/css/newsInformation.css";
</style>
