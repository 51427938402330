<!--
 * @Author: xr
 * @Date: 2022-02-24 10:44:49
 * @LastEditors: xr
 * @LastEditTime: 2022-03-03 14:36:16
 * @FilePath: \postStation-office\src\components\Journalism.vue
-->
<template>
  <div>
    <a-row style="padding-top: 2px;min-height:68vh;">
      <a-row class="title-section sub-component-background-color">
        <a-empty v-if="newsData.length===0" class="empty-data"/>
        <div v-else v-for="(item,i) in newsData" :key="i" class="news-right-component" @click="details(item)">
          <span class="title">{{ item.newsTopic || item.title || item.headline || item.industryTopic}}</span>
          <span class="news-time">{{item.createTime.substring(0,10)}}</span>
          <p class="detailed-information" style="margin-bottom: 0px;">...[详细信息]</p>
        </div>
      </a-row>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'Journalism',
  props: ['newsData'],
  methods: {
    /**
     * @description:跳转
     * @param {Object} item
     */
    details(item) {
      this.$emit('details', item.id)
    }
  },
  created() {
  }
}
</script>

<style scoped>

.title-section{
  min-height: 75.8vh;
}

.title{
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #155E94;
  cursor: pointer;
}

.title:hover{
  color: #FF0000;
}

.news-time{
  color:#666;
  float:right;
}

.detailed-information{
  padding: 6px 0px 0px 30px;
  font-size: 12px;
  cursor: pointer;
}

.news-right-component{
  width: 100%;
  min-height: 60px;
  border: 1px dotted #999;
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  padding: 10px 4px;
}

.news-right-component:hover{
  background-color: #F5F5F5;
}
@import "~@assets/css/base.css";
</style>
